.container-users-client {
  .ant-card {
    background-color: #f0f2f5;
    font-family: "Montserrat", sans-serif !important;
    border-radius: 6px;
    .ant-card-head {
      border-bottom: 1px solid #e0e0e0;
    }
    .ant-btn {
      background-color: #f0f2f5;
    }
  }
  .ant-space-item {
    margin-right: 10px;
  }
  .ant-select-dropdown {
    font-family: Montserrat, sans-serif !important;
  }

  #card-table {
    .ant-card-body {
      padding: 0px !important;
      .ant-table-row {
        background: #f0f2f5;
        font-family: "Montserrat", sans-serif !important;
        border-radius: 0px;
      }

      .ant-table-thead {
        border-radius: 0px;
        background: #e5e6e7;

        font-family: "Montserrat", sans-serif !important;
        th {
          background: #e5e6e7;
          border-radius: 0px;
        }
      }
    }
  }
  
}