.container-sider {
  .top-menu {
    margin-bottom: 10px;
    margin-top: 20px;
    padding: 15px;

    .logout {
      text-align: end;
      width: 60%;
      color: #fff;
      cursor: pointer;
    }
  }
  .body-menu {
    width: 100%;
    margin-top: 10%;
    .ant-menu {
      font-family: "Montserrat", sans-serif !important;
      background: #12475c !important;
    }
  }
  .ant-menu-submenu-title {
    padding-left: 5px !important;
    font-size: 13px !important;
  }
  .ant-menu-item {
    font-weight: 600;
    padding-left: 10px !important;
  }
  .ant-menu-item-selected {
    background-color: #09ac9c;
  }

  .ant-layout-sider-children {
    background: #12475c !important;
  }
  .ant-layout-sider-trigger {
    background: #12475c !important;
  }
  .logo-login {
    width: 250px;
    margin-top: 30px;
    margin-bottom: 10px;
  }
}

@media screen and (max-width: 1750px) {
  .logo-login {
    width: 210px !important;
   
  }
}
@media screen and (max-width: 1500px) {
  .logo-login {
    width: 190px !important;
   
  }
}
