.container-login {
  width: 100vw;
  height: 100vh;
  display: flex;
  flex-direction: row;
  justify-content: center;
  align-items: center;
  min-width: 400px;
  background-color: #fafafa;
  .login-left {
    width: 50%;
    height: 100%;
    background: url("images/login/logo-login.jpg") no-repeat center center;
    background-size: contain;
    background-color: #1d1d1b;
  }

  .login-right {
    width: 50%;
    display: flex;
    justify-content: center;
    align-items: center;

    .card-login {
      width: 80%;
      text-align: center;
      border: none;
      background-color: #fafafa;
      .text-welcome {
        text-align: left;
        font-family: "Montserrat", sans-serif;
      }
      .logo-login {
        margin-bottom: -60px;
        margin-left: -15%;
        width: 200px;
      }
      .button-login {
        text-align: center;
        border-radius: 6px !important;
        font-family: "Montserrat", sans-serif;
      }
      .new-password {
        font-family: "Montserrat", sans-serif;
        margin-left: 30px;
      }
      .social-login {
        display: flex;
        justify-content: center;
        margin-top: 20px;
        a {
          margin: 0 10px;
        }
      }
    }
  }

  .ant-input {
    height: 40px;
  }
}

.ant-form-item-explain-error {
  text-align: center;
}
.ant-input-affix-wrapper {
  border-radius: 6px !important;
  background-color: #fafafa;
  background: #fafafa;
  input {
    background: #fafafa;
  }
}
.modal-password {
  .ant-input-affix-wrapper {
    border-radius: 5px !important;
  }
}
.ant-statistic-content {
  font-size: 15px !important;
}

@media screen and (max-width: 1500px) {
  .card-login {
    width: 100% !important;
    .text-welcome {
      font-size: 12px;
      h2 {
        font-size: 25px;
      }
    }
    .new-password {
      margin-left: 0px !important;
    }
  }
}

@media screen and (max-width: 800px) {
  .login-left {
    width: 0% !important;
  }
  .login-right {
    width: 80% !important;
  }
}



@media screen and (max-width: 500px) {
  
  .login-right {
    width: 100% !important;
    margin-left: -70px !important;
  }
  .logo-login {
    margin-left: 10% !important;
  }
}

