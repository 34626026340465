.container-user {
    padding: 30px;
    text-align: center;

    .card-user{
        padding: 30px;
        border-radius: 8px;
        background: #ECECEC;
        height: 100%;

        .ant-card css-dev-only-do-not-override-ixblex{
          height: 100%;

        }
        .button-user{
          background-color: green;
        }
        .button-action{
          background-color: rgb(0, 0, 0);
        }
        
        .button-disable{
          margin-left: 10px;
          background-color: rgb(255, 0, 0);
        }
        .button-user{
          background-color: green;
        }

    }


  }


